html {
    box-sizing: border-box;
}

*,
*:before,
*:after {
    box-sizing: inherit;
}

ul {
    list-style-type: none;
    padding: 0;
}

a {
    text-decoration: none;
    color: inherit;
}

.carousel__wrap {
    /* margin-top: 10%; */
    align-items: center;
    display: flex;
    flex: 1;
    justify-content: center;
    position: relative;
    width: 100%;
    /* left: 50%; */
    left: 50%;
    border-radius: 50%;
    transform: translateX(-50%);
}

.carousel__inner {
    /* height: 30rem; */
    /* height: 20rem; */
    /* height: 15rem; */
    height: 8rem;
    position: relative;
    /* width: calc(30rem * 3); */
    width: calc(20rem * 10);
}

.carousel__container {
    height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.carousel__slide-list {
    height: 100%;
    left: 50%;
    list-style-type: none;
    margin: 0;
    padding: 0;
    /* transform: translateX(-50%); */
    /* position: absolute;
    transform: translateX(-50%);
    /* width: calc((5 + 0.5) * 30rem * 2); */
    width: 100%;
}

.carousel__slide-item {
    display: inline-block;
    /* height: 30rem; */
     height: 8rem; 
    margin: 0;
   /* padding: 1rem; */
    position: absolute;
    /* transition: 0.3s ease-out; */
    /* width: 30rem; */
    width: 9rem;
}

.carousel__slide-item-img-link {
    /* cursor: zoom-in; */
    /* display: flex; */
    /* height: 100%; */
    /* overflow: hidden; */
    /* position: relative; */
    /* width: 100%; */
    height: 100%;
    width: 100%;
}

.carousel__slide-item-img-link img {
    height: 100%;
    object-fit: cover;
    transition: all 0.5s ease;
    width: 100%;
}
/* 
.carousel__slide-item-img-link::after {
    align-items: center;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    content: 'read more';
    display: flex;
    height: 50%;
    justify-content: center;
    opacity: 0;
    position: absolute;
    transition: all 0.5s ease;
    width: 50%;
} */

/* .carousel__slide-item-img-link:hover::after {
    opacity: 1;
}

.carousel__slide-item-img-link:hover img {
    transform: scale(1.3);
}

.carousel-slide-item__body {
    bottom: -2.5rem;
    height: 10%;
    position: absolute;
} */

.carousel-slide-item__body h4 {
    /* margin: 0.7rem 0 0; */
    text-transform: uppercase;
}

/* .carousel-slide-item__body p {
    font-size: 1.2rem;
    line-height: 1.3;
    margin: 0.7rem 0 0;
} */

.carousel__btn {
    align-items: center;
    background: 0;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    position: absolute;
    top: 25%;
    transform: translateY(-50%);
}

.carousel__btn--prev {
    /* left: -10rem; */
    left: -5rem;
    background-color: #AC3533;
    padding: 15px;
    border-radius: 50%;
}

.carousel__btn--next {
    /* right: -10rem; */
    right: -5rem;
    background-color: #AC3533;
    padding: 15px;
    border-radius: 50%;
}

.carousel__btn-arrow {
    /* border: solid black; */
    /* border-width: 0 0.4rem 0.4rem 0; */
    border-width: 0 0.2rem 0.2rem 0;
    /* height: 6rem; */
    height: 1rem;
    padding: 3px;
    /* width: 6rem; */
    width: 1rem;
    z-index: 10;
    color: #fff;
}

.carousel__btn-arrow--left {
    transform: rotate(135deg);
}

.carousel__btn-arrow--right {
    transform: rotate(-45deg);
}

.carousel__dots {
    display: inline-block;
    left: 50%;
    margin-top: 2rem;
    position: absolute;
    transform: translateX(-50%);
}

.dot {
    background: #ccc;
    border: 0;
    border-radius: 50%;
    cursor: pointer;
    height: 2rem;
    margin: 0 0.3rem;
    outline: none;
    transform: scale(0.5);
    width: 2rem;
}

.dot.active {
    background: black;
}

/* @media (max-width: 768px) {
    .carousel__inner {
        width: calc(10rem * 4); 
    }

    .carousel__slide-item {
        width: calc(100% / 4); 
        position: relative; 
        transform: none !important;
    }

    .carousel__wrap {
        flex-direction: column;
    }

    .carousel__btn--prev,
    .carousel__btn--next {
        top: auto;
        bottom: -2rem;
        transform: none;
    }

    .carousel__btn--prev {
        left: 25%;
    }

    .carousel__btn--next {
        right: 25%;
    }
} */

