@font-face {
  font-family: 'Manrope';
  src: url('./assets/fonts/Manrope-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* html {
  scroll-behavior: smooth;
}


body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
  /* overflow-x: hidden; */
/* } */
 
/* Smooth scrolling behavior */
html {
  scroll-behavior: smooth;
  overflow-y: hidden; /* Hides the scrollbar */
  height: 100%; /* Ensures full height scrolling */
}

/* General body styling */
body {
  margin: 0;
  font-family: 'Manrope', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: auto; /* Allows scrolling without showing scrollbar */
  height: 100%; /* Ensures proper scrolling height */
  scrollbar-width: none; /* Hides scrollbar in Firefox */
}

/* For Webkit-based browsers (Chrome, Safari, Edge, etc.) */
body::-webkit-scrollbar {
  width: 0; /* Hides the scrollbar */
  height: 0;
}

/* Prevent scrollbars from appearing on all elements */
* {
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scrollbar-width: none; /* Firefox */
}

/* Additional Webkit-based rule for child elements */
*::-webkit-scrollbar {
  display: none; /* Hides scrollbar for all elements */
}


.register_bg_clr {
  background-color: #ffc2d1a6;
}

.border_clr {
  border: #AC3533 !important;
}

.mybzb_bg_pinkclr {
  background-color: #FDEDF1;
}

.mybzb_text_clr {
  color: #AC3533;
}

.mybzb_bg_clr {
  background-color: #AC3533;
}

.mybzbBgClr {
  background-color: #fff;
  border: #AC3533 !important;
}

.home_heading {
  display: inline-block;
  max-width: 100%;
  height: auto;
}

.Gallery__Indicators>div:first-child,
.swiper-pagination .swiper-pagination-bullet .swiper-pagination-bullet-active {
  background: #AC3533 !important;
}

[type='radio']:checked {
  color: #AC3533;
}

select option:hover {
  background-color: #d31212;
}

@media (max-width: 768px) {
  .mobile_help {
    flex-basis: 100% !important;
  }
}

.carousel__wrap {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  gap: 10px;
}

.carousel__slide-item-category {
  cursor: pointer;
  border-radius: 8px;
}

@media (max-width: 768px) {
  .carousel__wrap {
    grid-template-columns: repeat(4, 1fr);
  }
}

.custom-thumbnails {
  /* Your custom styles here */
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 10px;
}

/* @media only screen and (max-width: 768px) {
    .mobile-grid-classes {
        grid-template-columns: repeat(4, 1fr);
        gap: 10px;
    }
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .mobile-grid-classes {
        grid-template-columns: repeat(5, 1fr);
        gap: 15px;
    }
}

@media only screen and (min-width: 1025px) {
    .mobile-grid-classes {
        grid-template-columns: repeat(8, 1fr);
        gap: 20px;
    }
} */
/* .gallery-image {
    width: 100%;
    height: auto;
    object-fit: cover;
    border-radius: 10px;
  } */

/* 2xl and above */
/* @media only screen and (min-width: 1536px) {
    .mobile-grid-classes {
      grid-template-columns: repeat(8, 1fr);
      
      gap: 10px;
    }
  } */

/* xl */
/* @media only screen and (min-width: 1280px) and (max-width: 1535px) {
    .mobile-grid-classes {
      grid-template-columns: repeat(8, 1fr);
      
      gap: 10px;
    }
  } */

/* lg */
/* @media only screen and (min-width: 1024px) and (max-width: 1279px) {
    .mobile-grid-classes {
      grid-template-columns: repeat(8, 1fr);
      
      gap: 10px;
    }
  } */

/* md */
/* @media only screen and (min-width: 768px) and (max-width: 1023px) {
    .mobile-grid-classes {
      grid-template-columns: repeat(7, 1fr);
      
      gap: 10px;
    }
  } */

/* sm */
@media only screen and (min-width: 640px) and (max-width: 767px) {
  .mobile-grid-classes {
    grid-template-columns: repeat(5, 1fr);

  }
}

/* sm */
@media only screen and (min-width: 540px) and (max-width: 639px) {
  .mobile-grid-classes {
    /* width: calc(100% / 5); */
    grid-template-columns: repeat(5, 1fr);

    gap: 10px;
  }
}

/* sm */
@media only screen and (min-width: 440px) and (max-width: 539px) {
  .mobile-grid-classes {
    /* width: calc(100% / 4); */
    grid-template-columns: repeat(4, 1fr);

    gap: 10px;
  }
}

/* sm */
@media only screen and (min-width: 340px) and (max-width: 439px) {
  .mobile-grid-classes {
    /* width: calc(100% / 3); */
    grid-template-columns: repeat(3, 1fr);
    gap: 10px;
  }
}

/* sm */
@media only screen and (min-width: 240px) and (max-width: 339px) {
  .mobile-grid-classes {
    /* width: calc(100% / 2); */
    grid-template-columns: repeat(4, 1fr);
    /* margin-right: 15px;
      margin-left: 15px; */
    gap: 10px;
  }
}

/* xs */
@media only screen and (max-width: 239px) {
  .mobile-grid-classes {
    width: 100%;

  }
}

.tooltip-mobile {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  visibility: hidden;
  opacity: 0;
  transition: opacity 0.3s;
}

.tooltip-mobile:hover {
  visibility: visible;
  opacity: 1;
}

.tooltip-text {
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 12px;
}

/* Mobile styles */
@media only screen and (max-width: 768px) {
  .tooltip-mobile {
    display: block;
  }

  .tooltip-text {
    font-size: 10px;
  }
}

/* .Gallery__Indicators {
  display: none !important;
} */

/* @media (max-width: 767px) {
  .hide-on-mobile {
      display: none;
  }
} */

.Toastify__close-button {
  display: none;
}

.zoom_in_pp_modal {
  visibility: visible;
  animation-duration: 2500ms;
  animation: zoomIn 1s ease;

}

@keyframes zoomIn {
  0% {
    transform: scale(0.5);
  }

  100% {
    transform: scale(1);
  }
}

input[type="checkbox"]:checked {
  background-color: #AC3533;
  border-color: #AC3533;
}

input[type="checkbox"]:checked:hover {
  background-color: #AC3533;
  border-color: #AC3533;
}

.Gallery__NavigationArrow {
  display: none !important;
}

.animate-slide-down {
  transition: transform 0.5s ease-in-out;
  transform: translateY(0);
}

.animate-slide-down:not(.fixed) {
  transform: translateY(-100%);
}


.scroll-aware-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  z-index: 10;
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.fixed-container {
  position: fixed;
  top: 0;
}

.header {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.navbar {
  transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
}

.animate-header {
  transform: translateY(0);
  opacity: 1;
}

.animate-navbar {
  transform: translateY(0);
  opacity: 1;
}

.scroll-aware-container .header {
  transform: translateY(-100%);
  opacity: 0;
}

.scroll-aware-container .navbar {
  transform: translateY(-100%);
  opacity: 0;
}

/* ScrollAwareComponent.css */

.header-container {
  transition: transform 0.5s ease-in-out;
}

.navbar-container {
  transition: slideInDown 0.5s ease-in-out;
}

.slide-down {
  animation: slideDown 0.5s forwards;
}

.slide-up {
  animation: slideUp 0.5s forwards;
}

@keyframes slideDown {
  from {
    transform: translateY(-100%);
  }

  to {
    transform: translateY(0);
  }
}

@keyframes slideUp {
  from {
    transform: translateY(100%);
  }

  to {
    transform: translateY(0);
  }
}

.animate-slide-navbar-down {
  animation: slideInDown 0.5s ease-in-out;
}

@keyframes slideInDown {
  0% {
    transform: translateY(-100%);
  }

  100% {
    transform: translateY(0);
  }
}

.header-container,
.navbar-container {
  transition: all 0.3s ease-in-out;
  /* Smooth transition for all properties */
}

.fixed {
  transition: top 0.3s ease-in-out;
  /* Smooth transition for position change */
}


@keyframes zoomIn {
  0% {
    transform: scale(0);
    opacity: 0;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

@keyframes zoomOut {
  0% {
    transform: scale(1);
    opacity: 1;
  }

  100% {
    transform: scale(0);
    opacity: 0;
  }
}

.zoom-in {
  animation: zoomIn 0.3s ease-in-out forwards;
}

.zoom-out {
  animation: zoomOut 0.3s ease-in-out forwards;
}

.noUnderline {
  text-decoration: none !important;
}


span .noUnderline {
  text-decoration: none !important;
}


/* .loader{
  align-items: center;
} */


/* FAQ Accordian CSS */
.accordion {
  position: relative;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  width: 1300px;
  max-width: 600px;
  margin: 0 auto;
  padding: 1rem;
}

@media (max-width: 649px) {
  .accordion {
    position: relative;
    width: 600px;
    max-width: 300px;
  }
}

@media (max-width: 444px) {
  .faq-buttons {
    display: flex;
    flex-direction: column;
  }
}

.faq-item {
  border-bottom: 1px solid #ddd;
  padding: 1rem 0;
  cursor: pointer;
}

.faq-question {
  display: flex;
  justify-content: space-between;
  font-weight: 600;
  font-size: 1rem;
  color: #2a2a2a;
}

.faq-icon {
  font-size: 1.2rem;
  color: #888;
  transition: transform 0.3s;
}

.faq-icon.open {
  transform: rotate(180deg);
}

.faq-answer {
  font-size: 0.9rem;
  color: #555;
  margin-top: 0.5rem;
  padding-left: 1rem;
  line-height: 1.6;
}

.faq-item:last-child {
  border-bottom: none;
}




@media only screen and (min-width: 768px) and (max-width: 845px) {
  .footer-privacy-image {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 907px) {
  .footer-terms-image {
    margin-top: 60px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 891px) {
  .footer-seller-image {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 886px) and (max-width: 1019px) {
  .footer-fulfilment-image {
    margin-top: 60px;
  }
}

@media (max-width: 885px) {
  .footer-fulfilment-image {
    margin-top: 80px;
  }
}

@media only screen and (min-width: 870px) and (max-width: 1014px) {
  .footer-purchase-protection-image {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 869px) {
  .footer-purchase-protection-image {
    margin-top: 70px;
  }
}

@media only screen and (min-width: 769px) and (max-width: 891px) {
  .footer-shipping-image {
    margin-top: 40px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 935px) {
  .footer-cancellation-image {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 857px) and (max-width: 935px) {
  .footer-fraud-warning-image {
    margin-top: 50px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 856px) {
  .footer-fraud-warning-image {
    margin-top: 80px;
  }
}

@media (max-width: 1025px) {
  .hide-at-1021px {
    display: none;
  }
}

@media (min-width: 1026px) {
  .hide-logo-at-larger-screens {
    display: none;
  }
}

@media (max-width: 545px) {
  .deactive-user-img {
    height: 400px;
    width: 400px;
  }
}
@media (max-width: 405px) {
  .deactive-user-img {
    height: 300px;
    width: 300px;
  }
}
@media (max-width: 275px) {
  .deactive-user-img {
    height: 200px;
    width: 200px;
  }
}

@media (max-width: 405px) {
  .reviews-mobile-view {
  }
}

.search-history{
  margin-left: -16px;
}